<template>
  <v-container fluid class="d-flex justify-center pa-8" v-if="userRole.toUpperCase().includes('BLACKLIST')">
    <div>
      <v-row
        justify="center"
        class="null-item"
      >
        <v-col class="d-flex justify-center pb-0" cols="10">
          <v-img
            class="image"
            :src="require('../../assets/bus-homepage.svg')"
          ></v-img>
        </v-col>
        <v-col class="d-flex justify-center py-0 mt-4" cols="12">
          <h2 class="text-center">{{ $t("SuspendWarning") }}</h2>
        </v-col>
      </v-row>
    </div>
  </v-container>
  <v-container fluid class="home ma-0 pa-0" v-else>
    <v-row class="primary ma-0" no-gutters :style="`background-color: ${$partnerACL.getBackgroundColorApp()}!important`">
      <v-container fluid class="ma-0">
        <div align="center">
          <p class="title" :style="`color: ${$partnerACL.getTitleColor()}!important`">{{ $t("ElectronicBusTicketSolution") }}</p>
          <p class="desc-title" :style="`color: ${$partnerACL.getTitleColor()}!important`">{{ $t("describeBuyTicket") }}</p>
        </div>
      </v-container>
    </v-row>
    <v-row class="ma-0">
      <v-col
        v-for="item in $partnerACL.getSubMenu()"
        :key="item.title"
       class="card-content" cols="12">
        <v-card :to="item.path" outlined class="ma-4 rounded-card">
          <v-row class="pl-5 pt-1 pb-1" align="center">
            <v-col v-if="item.icon" class="mr-0 pr-0 d-flex justify-center" cols="3">
              <v-img
                max-width="77"
                :src="item.icon"
              >
              </v-img>
            </v-col>
            <v-col cols="9" class="ml-0 pl-0">
              <v-card-title>{{ $t(item.title) }}</v-card-title>
              <v-card-subtitle class="ml-1">{{
                $t(item.title)
              }}</v-card-subtitle>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
      <!-- <v-col class="card-content" cols="12">
        <v-card :to="'/multitrips'" outlined class="ma-4 rounded-card">
          <v-row class="pl-5 pt-1 pb-1" align="center">
            <v-col class="mr-0 pr-0 d-flex justify-center" cols="3">
              <v-img
                max-width="77"
                :src="require('../../assets/buy-ticket/multidays.svg')"
              >
              </v-img>
            </v-col>
            <v-col cols="9" class="ml-0 pl-0">
              <v-card-title>{{ $t("Evoucher") }}</v-card-title>
              <v-card-subtitle class="ml-1">{{
                $t("Evoucher")
              }}</v-card-subtitle>
            </v-col>
          </v-row>
        </v-card>
      </v-col> -->
      <v-col
        class="card-content"
        cols="12"
        v-if="
          $store.state.auth.user_role == 'PASSENGER-STUDENT' &&
          provider != 'GOBUS'
        "
      >
        <!-- <div :to="'/flatefare'" class="ma-4 flat-fare"> -->
        <v-card :to="'/studentfare'" outlined class="ma-4 rounded-card">
          <v-row class="pl-5 pt-1 pb-1" align="center">
            <v-col class="mr-0 pr-0 d-flex justify-center" cols="3">
              <v-img
                max-width="77"
                :src="require('../../assets/buy-ticket/multidays.svg')"
              >
              </v-img>
            </v-col>
            <v-col cols="9" class="ml-0 pl-0">
              <v-card-title>{{ $t("StudentTicket") }}</v-card-title>
              <v-card-subtitle class="ml-1">{{
                $t("StudentTicket")
              }}</v-card-subtitle>
            </v-col>
          </v-row>
        </v-card>
        <!-- </div> -->
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
// @ is an alias to /src
import store from "@/store";
export default {
  name: "Home",
  components: {},
  data() {
    return {
      colors: [
        "green",
        "secondary",
        "yellow darken-4",
        "red lighten-2",
        "orange darken-1",
      ],
      cycle: false,
      slides: ["First", "Second", "Third", "Fourth", "Fifth"],
      customerID: store.state.auth.userData.customerID,
    };
  },
  computed: {
    provider() {
      return this.$store.state.auth.provider;
    },
    userRole() {
      return this.$store.state.auth.user_role;
    }
  },
  methods: {
    toCartPage() {
      this.$router.push({ name: "Cart" });
    },
    popStateListener() {
      // force to go back to home page when user click back button on browser/device
      this.$router.replace({ name: "home" });
    }
  },
  async mounted() {
    const payload = {
      customerID: this.customerID,
    };
    this.$store
      .dispatch("purchaseticket/getQtyCart", payload)
      .then((response) => {
        this.items = [];
        for (let data of response.data) {
          this.items.push(data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
    
    window.addEventListener('popstate', this.popStateListener);
  },
  beforeDestroy() {
    window.removeEventListener('popstate', this.popStateListener);
  }
};
</script>

<style lang="scss" scoped>
.title {
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 118.88%;

  /* identical to box height, or 24px */

  text-transform: capitalize;
  color: #ffffff;
}

.color-bg {
  background-image: url("../../assets/header-decorations.svg") !important;

  background-position: right bottom;
  background-repeat: no-repeat;
  background-size: 150px 150px;
  position: relative;
}

.desc-title {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 118.88%;
  /* or 14px */

  text-align: center;

  color: #ffffff;
}

/* If the screen size is 673px or more, set the font-size of <div> to 80px */
@media only screen and (min-width: 601px) {
  .flat-fare {
    background: url("../../assets/buy-ticket/flat.png") no-repeat;
    background-size: cover;
    width: 100%;
    height: 500px;
  }

  .rounded-card {
    border-radius: 15px !important;
  }
}

/* If the screen size is 672 or less, set the font-size of <div> to 30px */
@media only screen and (max-width: 600px) {
  .card-content {
    margin-bottom: -30px;
  }
  .v-card__title {
    padding: 10px;
    padding-left: 20px;
  }

  .flat-fare {
    background: url("../../assets/buy-ticket/flat.png");
    // border-radius: 15px !important;
    // background-color:#e9f9eb;
    width: 100%;
    height: 190px;
    // margin-right: 20%;
    left: 16px;
    // top: 229px;
    // background: linear-gradient(180deg, #FFFFFF 0%, #FFE9FC 100%);
  }

  .rounded-card {
    // background-image: require('../../assets/photo-profile.png');
    border-radius: 15px !important;
    // background: linear-gradient(180deg, #FFFFFF 0%, #FFE9FC 100%);
  }
}
</style>